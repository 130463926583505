import React from 'react'
import styled from '@emotion/styled'


export interface Props extends React.HTMLAttributes<HTMLElement> {
  name: string // https://material.io/tools/icons/?style=baseline
}

const Icon: React.FC<Props> = styled(({name, className, ...props}: Props) => <i className={`material-icons ${className}`} {...props}>{name}</i>)`
  font-size: ${props => props.theme.size_1_5} !important;
`

export default Icon
